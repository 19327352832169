<template>
  <div class="drawer-box">
    <section class="user-avatar-box">
      <div class="avatar-box" v-show="showUserInfo">
        <img class="avatar" :src="getAvatar" @click="showAvatar" />
        <!--        <v-tooltip bottom color="success" v-if="isShowAnnualIcon">-->
        <!--          <template v-slot:activator="{ on, attrs }">-->
        <!--            <img-->
        <!--              class="annual-membership-tip cursor"-->
        <!--              v-bind="attrs"-->
        <!--              v-on="on"-->
        <!--              src="@/assets/image/icon_annual.png"-->
        <!--            />-->
        <!--          </template>-->
        <!--          <span>{{ showEndTime(annualVip.end_time) }}</span>-->
        <!--        </v-tooltip>-->
        <!--        <img-->
        <!--          v-else-->
        <!--          class="annual-membership-tip cursor"-->
        <!--          @click="handleBecomeAnnualMembership"-->
        <!--          src="@/assets/image/icon_annual_normal.png"-->
        <!--        />-->
        <div
          v-if="
            userIdentityStatus === UserStatus.TemporaryUsers ||
            userIdentityStatus === UserStatus.ArrearsUsers
          "
          class="status-tip"
        >
          {{ getIdentityTxt(userIdentityStatus) }}
        </div>
      </div>
    </section>
    <section class="user-info-box" v-show="showUserInfo">
      <dl class="show-info">
        <dt>{{ $t("navDrawer.nickName") }} :</dt>
        <dd>
          <section v-if="!isEditNameType">
            <span>{{ userNickName }} </span>
            <v-btn
              icon
              color="#f2c832"
              class="btn-edit"
              @click="handleEditName"
            >
              <v-icon size="14">mdi-pencil</v-icon>
            </v-btn>
          </section>
          <section v-if="isEditNameType">
            <v-text-field v-model="editName" class="edit-name"></v-text-field>
            <v-btn
              icon
              color="#539dd7"
              class="btn-check"
              :disabled="editName.length === 0"
              @click="handleCheckEditName"
            >
              <v-icon size="14">mdi-check</v-icon>
            </v-btn>
            <v-btn
              icon
              color="#539dd7"
              class="btn-cancel"
              @click="handleCancelEditName"
            >
              <v-icon size="14">mdi-close</v-icon>
            </v-btn>
          </section>
        </dd>
        <dt>{{ $t("navDrawer.title") }} :</dt>
        <dd>{{ title }}</dd>
        <dt></dt>
        <dd class="progress-bar-box">
          <div class="progress-bar progress-bar-linear">
            <v-progress-linear
              color="deep-orange"
              height="30"
              :value="titleValue"
              rounded
            ></v-progress-linear>
            <span class="progress-tip">
              ${{ getTotalPrice(title_current_exp) }}/${{
                getTotalPrice(title_need_exp)
              }}
            </span>
          </div>
          <v-icon color="#ff7043" :size="fontSize" class="icon-diamond-stone">
            mdi-diamond-stone
          </v-icon>
        </dd>
        <dt v-if="Number(licence) >= 1">
          {{ $t("navDrawer.invitationCode") }} :
        </dt>
        <dd v-if="Number(licence) >= 1" class="code-txt">
          {{ invitation_code }}
        </dd>
        <dt v-if="Number(licence) >= 1">{{ $t("navDrawer.wallet") }}($) :</dt>
        <dd v-if="Number(licence) >= 1" class="show-module-number">
          ${{ getTotalPrice(amount) }}
        </dd>
        <dt v-if="Number(licence) >= 1">
          {{ $t("navDrawer.maximumBalanceTip") }} :
        </dt>
        <dd v-if="Number(licence) >= 1" class="progress-bar-box">
          <div class="progress-bar progress-bar-linear">
            <v-progress-linear
              color="#35cbac"
              height="30"
              :value="progressValue"
              rounded
            ></v-progress-linear>
            <span class="progress-tip">
              ${{ getTotalPrice(withdrawAmount) }}/${{
                getTotalPrice(licenceMaxAmount)
              }}
            </span>
          </div>
          <v-icon
            @click="handleRefreshMaxAmount"
            color="#35cbac"
            :size="fontSize"
            class="icon-refresh"
          >
            mdi-refresh
          </v-icon>
        </dd>
        <!--        <dt v-if="monthlyFee.is_need_2_pay_monthly_fee">-->
        <!--          {{ $t("navDrawer.monthlyFee") }} :-->
        <!--        </dt>-->
        <!--        <dd-->
        <!--          class="progress-bar-box"-->
        <!--          v-if="monthlyFee.is_need_2_pay_monthly_fee"-->
        <!--        >-->
        <!--          <div class="progress-bar progress-bar-linear">-->
        <!--            <v-progress-linear-->
        <!--              color="rgb(235 185 55)"-->
        <!--              height="30"-->
        <!--              :value="monthlyFeeValue"-->
        <!--              rounded-->
        <!--            ></v-progress-linear>-->
        <!--            <span class="progress-tip"-->
        <!--              >${{ getTotalPrice(monthlyFee.current_amount) }}/${{-->
        <!--                getTotalPrice(monthlyFee.need_amount)-->
        <!--              }}</span-->
        <!--            >-->
        <!--          </div>-->
        <!--          <v-icon-->
        <!--            @click="handleRecharge"-->
        <!--            :color="-->
        <!--              monthlyFee.is_need_2_pay_monthly_fee ? '#539dd7' : '#d4d4d4'-->
        <!--            "-->
        <!--            :size="fontSize"-->
        <!--            class="icon-recharge"-->
        <!--          >-->
        <!--            mdi-battery-plus-variant-->
        <!--          </v-icon>-->
        <!--        </dd>-->

        <dt>{{ $t("navDrawer.deposit") }} :</dt>
        <dd class="progress-bar-box">
          <div class="progress-bar progress-bar-linear">
            <v-progress-linear
              color="rgb(235 185 55)"
              height="30"
              :value="storageValue"
              rounded
            ></v-progress-linear>
            <span class="progress-tip"
              >${{ storage.amount / 100 }}/${{
                storage.upgrade_amount / 100
              }}</span
            >
          </div>
          <v-icon
            v-if="storage.amount < storage.upgrade_amount"
            @click="handleStorage"
            :color="'#539dd7'"
            :size="fontSize"
            class="icon-recharge"
          >
            savings
          </v-icon>
          <v-icon
            v-if="storage.amount >= storage.upgrade_amount"
            @click="handleUpgrade"
            :color="'#539dd7'"
            :size="fontSize"
            class="icon-recharge"
          >
            mdi-chevron-triple-up
          </v-icon>
        </dd>
      </dl>
      <div class="footer-btn">
        <v-btn
          height="58px"
          block
          elevation="0"
          color="#539dd7"
          @click="handleToHome"
          class="btn-personal footer-btn-item"
          v-show="
            $route.path !== '/goods' &&
            !isLicenceMax &&
            String(userIdentityStatus) === String(UserStatus.NormalUsers)
          "
        >
          {{ $t("navDrawer.upgrade") }}
        </v-btn>
        <v-btn
          width="100%"
          height="58px"
          block
          elevation="0"
          color="#539dd7"
          @click="handleToPersonal"
          class="btn-personal footer-btn-item"
          v-if="$route.path !== '/' && Number(licence) >= 1"
          v-ga="{
            eventCategory: 'button',
            eventLabel: 'click personal center',
          }"
        >
          {{ $t("navDrawer.personalCenter") }}
        </v-btn>
        <v-btn
          width="100%"
          height="58px"
          block
          elevation="0"
          outlined
          color="#539dd7"
          @click="readyToWithdrawal"
          class="footer-btn-item"
          v-if="
            String(userIdentityStatus) === String(UserStatus.NormalUsers) &&
            Number(licence) >= 1
          "
          v-ga="{
            eventCategory: 'button',
            eventLabel: 'click withdrawal',
          }"
        >
          {{ $t("navDrawer.withdrawal") }}
        </v-btn>
        <v-btn
          width="100%"
          height="58px"
          block
          elevation="0"
          outlined
          color="#539dd7"
          class="footer-btn-item"
          @click="handleForMember"
          v-if="
            String(userIdentityStatus) === String(UserStatus.TemporaryUsers)
          "
          v-ga="{
            eventCategory: 'button',
            eventLabel: 'click became full member',
          }"
        >
          {{ $t("navDrawer.becameFullMember") }}
        </v-btn>
      </div>
      <div v-if="false">
        <v-list flat>
          <v-list-item-group v-model="navBarActive" color="indigo">
            <v-list-item
              v-for="(item, i) in navBarArr"
              :key="i"
              class="v-list-item v-list-item--link theme--light"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </section>
    <!--    <section class="user-avatar">-->
    <!--      <template v-if="isShowAvatar">-->
    <!--        <div class="avatar-panel" @click="isShowAvatar = false">-->
    <!--          <img :src="getAvatar" alt="User" />-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </section>-->
    <!-- dialog  -->
    <v-dialog v-model="readyToBuyDialog" width="500">
      <v-card>
        <v-card-title class="ready-to-buy-dialog-title text-h6 lighten-2">
          {{ $t("goods.invitationCodePrompt") }}
          <v-btn class="btn-close" icon @click="handleCloseReadyToBuyDialog">
            <v-icon color="#000000"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="teacher_code"
            :label="teacherCode"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#539dd7"
            elevation="0"
            width="100%"
            height="3.3em"
            @click="btnToBuy"
          >
            {{ $t("goods.buyNowBtn") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isShowDialog" persistent width="500">
      <v-card>
        <v-card-title class="dialog-title text-h6 lighten-2">
          {{ $t("dialog.enterWithdrawalAmountTitle") }}
          <v-btn class="btn-close" icon @click="handleCloseDialog">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="extractingAmount"
            :label="getWithdrawalAmountTitle"
            :placeholder="getEnterAmountTip"
            outlined
          ></v-text-field>
          <div class="alert-box" v-if="isShowAlert">
            {{ alertTxt }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#539dd7"
            elevation="0"
            width="100%"
            height="3.3em"
            :disabled="checkExtractingAmount"
            @click="handleWithdrawal"
            class="withdrawal-now-btn"
          >
            {{ $t("navDrawer.withdrawalNow") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isLoading" persistent width="30em">
      <v-card color="#ffffff" dark height="auto">
        <v-card-text>
          <div class="card-title">
            {{ $t("message.loading") }}
          </div>
          <v-progress-linear
            indeterminate
            height="6px"
            :rounded="true"
            color="#4caf50"
            class="mb-0 progress"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ confirmDialogTitle }}
        </v-card-title>
        <v-card-text>{{ confirmDialogContent }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-grey darken-1" text @click="btnCancel">
            {{ $t("dialog.disagree") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="btnConfirm">
            {{ $t("dialog.agree") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="storageConfirmDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ confirmDialogTitle }}
        </v-card-title>
        <v-card-text>{{ confirmDialogContent }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-grey darken-1" text @click="btnCancel">
            {{ $t("dialog.iie") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="btnStorageConfirm">
            {{ $t("dialog.hai") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="storageConfirmUpgradeDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("navDrawer.upgradeConfirmTitle") }}
        </v-card-title>
        <v-card-text>{{ $t("navDrawer.upgradeConfirmDoUpgrade") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-grey darken-1" text @click="btnCancel">
            {{ $t("dialog.disagree") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="btnStorageUpgradeConfirm">
            {{ $t("dialog.agree") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  bind_order,
  create_order,
  per_pay,
  storage,
  storage_upgrade,
  withdraw,
} from "@/api/goods";
import { change_nickname } from "@/api/user";
import { UserStatus } from "@/common/globalConstant.js";
import { recharge_platform_account } from "@/utils/connet";
import moment from "moment";
import "material-design-icons-iconfont/dist/material-design-icons.css";
export default {
  name: "UserProfile",
  data: () => ({
    goodsList: [],
    hoverIndex: -1,
    invite_code: "",
    teacher_code: "",
    readyToBuyDialog: false,
    isLoading: false,
    mini: false,
    isShowDialog: false,
    extractingAmount: "",
    canWithdrawalAmount: 0,
    isShowAlert: false,
    alertTxt: "",
    UserStatus,
    isEditNameType: false,
    editName: "",
    showUserInfo: false,
    confirmDialog: false,
    storageConfirmDialog: false,
    storageConfirmUpgradeDialog: false,
    navBarActive: 1,
    fontSize: 30,
    navBarArr: [
      {
        text: "Personal Center",
        icon: "mdi-crown",
      },
      {
        text: "Upgrade",
        icon: "mdi-shopping",
      },
    ],
    confirmDialogType: 0,
    confirmDialogTitle: "",
    confirmDialogContent: "",
    stateType: 0,
    loadingMessage: "loading",
  }),
  watch: {
    confirmDialogType: {
      handler: function (val) {
        switch (val) {
          case 7:
            this.confirmDialogTitle = this.$t("navDrawer.upgradeConfirmTitle");
            this.confirmDialogContent = this.$t("navDrawer.upgradeConfirm");
            break;
          case 6:
            this.confirmDialogTitle = this.$t(
              "navDrawer.becomeAnnualMembershipTitle"
            );
            this.confirmDialogContent = this.$t(
              "navDrawer.becomeAnnualMembershipContent"
            );
            break;
          case 5:
            this.confirmDialogTitle = this.$t(
              "navDrawer.refreshMaxAmountTitle"
            );
            this.confirmDialogContent = this.$t(
              "navDrawer.refreshMaxAmountContent"
            );
            break;
          case 4:
            this.confirmDialogTitle = this.$t(
              "navDrawer.becameFullMemberTitle"
            );
            this.confirmDialogContent = this.$t(
              "navDrawer.becameFullMemberContent"
            );
            break;
          case 3:
            this.confirmDialogTitle = this.$t("navDrawer.payMonthlyFeeTitle");
            this.confirmDialogContent = this.$t(
              "navDrawer.payMonthlyFeeContent"
            );
            break;
          default:
            break;
        }
      },
      immediate: true,
    },
    "$store.state.system.setLanguageIndex": {
      handler: function () {
        if (!this.userId) {
          this.initData();
        }
      },
      immediate: true,
    },
    isShowNavDrawer: {
      handler(newVal, oldVal) {
        if (newVal && !this.userId) {
          this.initData();
        }
      },
    },
  },
  computed: {
    ...mapState({
      title_current_exp: (state) => state.user.title_current_exp,
      title_need_exp: (state) => state.user.title_need_exp,
      walletAddress: (state) => state.user.walletAddress,
      licenceMaxAmount: (state) => state.user.licenceMaxAmount,
      withdrawAmount: (state) => state.user.withdrawAmount,
      totalAmount: (state) => state.user.totalAmount,
      amount: (state) => state.user.amount,
      invitation_code: (state) => state.user.invitation_code,
      userIdentityStatus: (state) => state.user.userIdentityStatus,
      pledgeAmount: (state) => state.user.pledgeAmount,
      userAvatar: (state) => state.user.userAvatar,
      title: (state) => state.user.title,
      licence: (state) => state.user.licence,
      isLicenceMax: (state) => state.user.isLicenceMax,
      userNickName: (state) => state.user.userNickName,
      annualVip: (state) => state.user.annualVip,
      monthlyFee: (state) => state.user.monthlyFee,
      language: (state) => state.system.language,
      isShowNavDrawer: (state) => state.system.isShowNavDrawer,
      userId: (state) => state.user.userId,
      storage: (state) => state.user.storage,
    }),
    isShowAnnualIcon() {
      return (
        this.annualVip?.end_time &&
        this.annualVip?.is_annual_vip &&
        this.annualVip?.show_icon
      );
    },
    getWithdrawalAmountTitle() {
      return this.$t("navDrawer.withdrawalAmountTitle");
    },
    showEndTime() {
      return (date = "2023-10-01T08:00:00+08:00") => {
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
      };
    },
    getEnterAmountTip() {
      return this.$t("navDrawer.enterAmountTip");
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    progressValue() {
      return (this.withdrawAmount / this.licenceMaxAmount) * 100;
    },
    titleValue() {
      return (this.title_current_exp / this.title_need_exp) * 100;
    },
    monthlyFeeValue() {
      if (this.monthlyFee.current_amount && this.monthlyFee.need_amount) {
        return (
          (this.monthlyFee.current_amount / this.monthlyFee.need_amount) * 100
        );
      } else {
        return 0;
      }
    },
    storageValue() {
      if (this.storage.amount && this.storage.upgrade_amount) {
        return (this.storage.amount / this.storage.upgrade_amount) * 100;
      } else {
        return 0;
      }
    },
    checkExtractingAmount() {
      return (
        this.extractingAmount <= 0 ||
        this.amount <= 0 ||
        parseFloat(this.extractingAmount * 100) > this.canWithdrawalAmount
      );
    },
    getAddress: () => {
      return (val) => {
        let left = val.slice(0, 6);
        let right = val.slice(val.length - 4, val.length);
        return `${left}...${right}`;
      };
    },
    getIdentityTxt: () => {
      return (val) => {
        let txt = "";
        switch (val) {
          case UserStatus.TemporaryUsers:
            txt = "Account Temporary";
            break;
          case UserStatus.ArrearsUsers:
            txt = "Account Arrears";
            break;
          default:
            txt = "Account Temporary";
            break;
        }
        return txt;
      };
    },
    getTotalPrice() {
      return (price) => {
        return parseFloat(price / 100).toFixed(2);
      };
    },
    getAvatar() {
      return this.userAvatar || require("@/assets/image/head.png");
    },
    teacherCode() {
      return this.$t("goods.teacherCode");
    },
  },
  created() {},
  methods: {
    initData() {
      this.getUserInfo();
      moment.locale(this.language === "jp" ? "ja" : this.language);
    },
    showAvatar() {
      this.$store.commit("user/setIsShowAvatar", true);
    },
    getUserInfo() {
      this.showUserInfo = false;
      this.$store.dispatch("user/getUserInfo").then((res) => {
        let can_withdrawal_amount = this.licenceMaxAmount - this.withdrawAmount;
        if (can_withdrawal_amount <= 0) {
          can_withdrawal_amount = 0;
        } else {
          if (this.amount <= can_withdrawal_amount) {
            can_withdrawal_amount = this.amount;
          }
        }
        this.canWithdrawalAmount =
          can_withdrawal_amount > 0 ? can_withdrawal_amount : 0;
        this.showUserInfo = true;
      });
    },
    handleCheckEditName() {
      if (this.editName.length === 0) {
        return;
      }
      change_nickname({
        nickname: this.editName,
      }).then((res) => {
        this.isEditNameType = false;
        this.$store.dispatch("user/getUserInfo");
      });
    },
    handleEditName() {
      this.isEditNameType = true;
      this.editName = this.userNickName;
    },
    handleCancelEditName() {
      this.isEditNameType = false;
      this.editName = "";
    },
    handleCloseDialog() {
      this.isShowDialog = false;
    },
    readyToWithdrawal() {
      this.extractingAmount = "";
      this.isShowDialog = true;
    },
    handleWithdrawal() {
      let extractingAmount = parseFloat(this.extractingAmount * 100);
      if (
        extractingAmount > 0 &&
        extractingAmount <= this.canWithdrawalAmount
      ) {
        this.isLoading = true;
        withdraw({
          amount: extractingAmount,
        })
          .then((res) => {
            this.isLoading = false;
            this.isShowDialog = false;
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: this.$t("navDrawer.successfulWithdrawal"),
            });
            this.$store.dispatch("user/getUserInfo");
          })
          .catch((err) => {
            this.isLoading = false;
            this.isShowDialog = false;
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: err.message || err || this.$t("network.tryAgain"),
            });
            console.log(err, this.$t("network.tryAgain"));
          });
      } else {
        this.alertTxt = this.$t("navDrawer.withdrawalErrorTip");
        this.isShowAlert = true;
      }
    },
    btnCancel() {
      this.storageConfirmDialog = false;
      this.storageConfirmUpgradeDialog = false;

      this.confirmDialog = false;
      this.confirmDialogType = 0;
    },
    btnConfirm() {
      this.teacher_code = "";
      this.confirmDialog = false;
      this.readyToBuyDialog = true;
    },
    btnStorageConfirm() {
      this.storageConfirmDialog = false;
      this.storageConfirmUpgradeDialog = false;

      storage().then((res) => {
        this.$store
          .dispatch("user/getUserInfo")
          .then(() => {
            this.isLoading = false;
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: res.message,
            });
            this.$router.push({
              path: "/",
            });
          })
          .catch((err) => {
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: err.message || err || this.$t("network.refreshAgain"),
            });
            this.isLoading = false;
          });
      });
    },
    btnStorageUpgradeConfirm() {
      this.storageConfirmDialog = false;
      this.storageConfirmUpgradeDialog = false;
      this.isLoading = true;
      storage_upgrade().then((res) => {
        this.$store
          .dispatch("user/getUserInfo")
          .then(() => {
            this.isLoading = false;
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: res.message,
            });
            this.$router.push({
              path: "/",
            });
          })
          .catch((err) => {
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: err.message || err || this.$t("network.refreshAgain"),
            });
            this.isLoading = false;
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    handleBecomeAnnualMembership() {
      // Annual membership cannot be purchased for Level 1
      if (this.licence <= 1) {
        this.$store.commit("snackbar/setSnackbar", {
          show: true,
          text: this.$t("navDrawer.annualMembershipErrorTip"),
        });
        return;
      }
      this.confirmDialog = true;
      this.confirmDialogType = 6;
    },
    handleRefreshMaxAmount() {
      this.confirmDialog = true;
      this.confirmDialogType = 5;
    },
    handleForMember() {
      this.confirmDialog = true;
      this.confirmDialogType = 4;
    },
    handleRecharge() {
      if (this.monthlyFee?.is_need_2_pay_monthly_fee) {
        this.confirmDialog = true;
        this.confirmDialogType = 3;
      }
    },
    handleStorage() {
      // if (this.storage.amount >= this.storage.upgrade_amount) {
      this.storageConfirmDialog = true;
      this.confirmDialogType = 7;
      // }
    },
    handleUpgrade() {
      if (this.storage.amount >= this.storage.upgrade_amount) {
        this.storageConfirmUpgradeDialog = true;
        this.confirmDialogType = 8;
      }
    },

    toCreateOrder(type) {
      if (this.isLoading) return;
      this.isLoading = true;
      this.readyToBuyDialog = false;
      this.loadingMessage = this.$t("message.waitingTip2");
      console.log("create_order", type, {
        type: type,
        teacher_code: this.teacher_code,
      });
      create_order({
        type: type,
        teacher_code: this.teacher_code,
      })
        .then((res) => {
          let data = res?.data ?? {};
          if (data?.order_id && data?.token_contract) {
            this.rechargePlatformAccount(data);
          } else {
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text:
                type === 3
                  ? this.$t("navDrawer.rechargeSuccess")
                  : this.$t("message.showText1"),
            });
            this.isLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: err.message || err || this.$t("message.showText4"),
          });
          this.isLoading = false;
        });
    },
    rechargePlatformAccount(res) {
      this.loadingMessage = this.$t("message.waitingTip3");
      recharge_platform_account(
        {
          decimal: res.token_decimals,
          amount: res.price,
          contractAddress: res.token_contract,
          receivingAddress: res.receiving_address,
          userAddress: this.walletAddress,
        },
        (txHash) => {
          this.stateType = 0;
          this.toBindOrder(res.order_id, txHash);
        },
        (err) => {
          console.log("err2", err);
          let text = "";
          if (err?.i18n) {
            text = this.$t("message." + err.i18n);
          } else {
            text = err;
          }
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: text,
          });
          this.isLoading = false;
        },
        (txHash) => {
          per_pay({
            order_id: res.order_id,
            tx_hash: txHash,
          })
            .then((data) => {})
            .catch((err) => {
              console.error("recharge platform account err:\n", err);
              this.$store.commit("snackbar/setSnackbar", {
                show: true,
                text:
                  err.message || err || this.$t("navDrawer.transactionFail"),
              });
              this.isLoading = false;
            });
        },
        (message) => {
          this.loadingMessage = this.$t("message." + message.i18n);
        }
      );
    },
    toBindOrder(order_id, txHash) {
      this.loadingMessage = this.$t("message.waitingTip4");
      bind_order({
        order_id: order_id,
        tx_hash: txHash,
      })
        .then((data) => {
          if (data.message === "success") {
            this.$store
              .dispatch("user/getUserInfo")
              .then(() => {
                this.isLoading = false;
                this.$store.commit("snackbar/setSnackbar", {
                  show: true,
                  text: data.message,
                });
                this.$router.push({
                  path: "/",
                });
              })
              .catch((err) => {
                this.$store.commit("snackbar/setSnackbar", {
                  show: true,
                  text: err.message || err || this.$t("network.refreshAgain"),
                });
                this.isLoading = false;
              });
          } else {
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: data?.message ?? this.$t("network.refreshAgain"),
            });
            this.isLoading = false;
          }
        })
        .catch((err) => {
          console.log("err", err);
          if (this.stateType <= 10) {
            this.stateType++;
            this.toBindOrder(order_id, txHash);
          } else {
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: err.message || this.$t("network.networkError"),
            });
            this.isLoading = false;
          }
        });
    },
    handleToHome() {
      if (this.$route.name !== "Home") {
        this.$router.push({
          path: "/goods",
        });
      }
    },
    handleToPersonal() {
      if (this.$route.path !== "Personal") {
        this.$router.push({
          path: "/",
        });
      }
    },
    handleCloseReadyToBuyDialog() {
      this.readyToBuyDialog = false;
      this.teacher_code = "";
    },
    btnToBuy() {
      if (this.confirmDialogType > 0) {
        this.toCreateOrder(this.confirmDialogType);
      }
    },
  },
};
</script>

<style scoped lang="scss">
$lagerSize: 1.2rem;
$balanceLagerSize: 1.3rem;
.drawer-box {
  //height: calc(100vh - 56px);
  overflow-y: auto;
}
.icon-logo {
  width: auto;
  height: 40px;
}
.dialog-title {
  position: relative;
  margin-bottom: 2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.avatar-box {
  width: 5em;
  height: 5em;
  position: absolute;
  left: 0.6em;
  top: 0;
  bottom: 0;
  margin: auto;
}

.avatar {
  width: 5em;
  height: 5em;
  display: block;
  border-radius: 50%;
  border: 1px solid rgba(83, 157, 215, 0.8);
  padding: 2px;
}

.btn-close {
  position: absolute;
  right: 10px;
}

.alert-box {
  color: red;
}

.user-info-box {
  width: 100%;
  padding: 0 0.6em;
  box-sizing: border-box;
}

.code-txt {
  color: #ffffff;
  background-color: #35cbac;
  padding: 0.1em 0.4em;
  display: inline-block;
}

.title-box {
  font-size: 1em;
  color: #999999;
}

.show-module-number {
  font-size: $balanceLagerSize !important;
  color: #ff5252;
}

.status-tip {
  width: 100%;
  text-align: center;
  background: #ef9a9a;
  color: red;
  font-size: 0.9em;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.licence-tip-box {
  width: 100%;
  background: #e1e1e1;
  padding: 0.6em;
  box-sizing: border-box;
  margin-bottom: 2em;
}

.show-info dt {
  margin-top: 1em;
  font-size: $lagerSize;
}

.show-info dd {
  font-size: $lagerSize;
}

.btn-personal {
  width: 100%;
  margin: 1.2em 0;
  color: #ffffff;
}

.withdraw-tip {
  background-color: #b4e8ff;
  padding: 4px;
  box-sizing: border-box;
  font-size: 0.6em;
}

.logo-txt {
  font-weight: bold;
  font-size: 20px;
  color: #539dd7;
}

.logo-first-txt {
  color: #539dd7;
  font-size: 20px;
}

.footer-btn {
  margin: 3em auto;
}

.progress-bar {
  position: relative;
  height: 30px;
}

.progress-bar-box {
  overflow: hidden;
}

.progress-bar-linear {
  width: calc(100% - 40px);
  display: inline-block;
  float: left;
}

.progress-tip {
  position: absolute;
  right: 10px;
  top: 0;
  line-height: 30px;
}

.footer-btn-item {
  font-size: $lagerSize;
}

.user-avatar-box {
  border-bottom: #ebebeb 1px solid;
  width: 100%;
  height: 8em;
  position: relative;
  background: url("/static/image/logo_dark3.png") repeat #f2f4f7;
  background-size: 6.2%;
  background-position: center;
}

.edit-name {
  width: 180px;
  display: inline-block;
}

.btn-check .btn-cancel {
  display: inline-block;
}

.btn-recharge {
  margin-bottom: 1em;
}

.annual-membership-tip {
  width: 3em;
  height: 3em;
  display: flex;
  position: absolute;
  top: 0;
  left: 5.4em;
}

.icon-refresh,
.icon-recharge,
.icon-diamond-stone {
  display: inline-block;
  float: right;
  line-height: 30px;
  vertical-align: top;
  margin-right: 4px;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.card-title {
  color: #333333;
  padding-top: 0.4em;
  line-height: 1em;
}

.progress {
  margin-top: 1.4em;
}

@media only screen and (min-width: 768px) {
  .user-avatar-box {
    height: 10em;
  }
  .avatar {
    width: 8em;
    height: 8em;
  }

  .user-info-box {
    font-size: 14px;
  }

  .avatar-box {
    margin: 1em auto;
  }

  .annual-membership-tip {
    width: 4em;
    height: 4em;
    display: flex;
    position: absolute;
    top: 0;
    left: 8em;
  }
}

.withdrawal-now-btn {
  color: #ffffff;
}

.avatar-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.avatar-panel img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
}
</style>
