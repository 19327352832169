<template>
  <div class="app-bar-box">
    <section class="user-avatar">
      <template v-if="isShowAvatar">
        <div class="avatar-panel" @click="closeAvatar">
          <img :src="getAvatar" alt="User" />
        </div>
      </template>
    </section>
    <nav-bar></nav-bar>
    <div class="banner-pledge-box text-unselect cursor" @click="goToPage">
      <div class="banner-title">
        <img class="logo-ton" src="/static/image/logo_ton.png" />
        Go to TON stake
        <v-icon size="24" color="#35cbac" class="banner-title-arrow"
          >mdi-arrow-right-circle</v-icon
        >
      </div>
    </div>
    <v-tabs
      v-model="tab"
      fixed-tabs
      @change="changeTab"
      v-if="$route.name === 'Personal' && isMobile"
    >
      <v-tabs-slider color="#539dd7"></v-tabs-slider>
      <v-tab v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>
    <section
      :class="[
        'app-bar-main',
        { 'app-bar-mobile': isMobile },
        { 'app-bar-web': !isMobile },
      ]"
    >
      <v-tabs
        v-model="tab"
        fixed-tabs
        v-if="$route.name === 'Personal' && !isMobile"
      >
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <router-view />
    </section>
    <v-navigation-drawer
      :width="getDrawerWidth"
      v-model="showDrawer"
      :absolute="isMobile"
      :temporary="isMobile"
      :permanent="!isMobile"
    >
      <nav-drawer></nav-drawer>
    </v-navigation-drawer>
    <div class="btn-show-nft" v-if="nftBtnShow" @click="goToMintNft">
      <svg
        t="1684145144411"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="3022"
        width="100"
        height="100"
      >
        <path
          d="M469.333333 640a64 64 0 1 1-64-64 64 64 0 0 1 64 64z m149.333334-64a64 64 0 1 0 64 64 64 64 0 0 0-64-64z m277.333333-21.333333a298.666667 298.666667 0 0 1-167.893333 268.16A212.053333 212.053333 0 0 1 618.666667 853.333333H405.333333a212.053333 212.053333 0 0 1-109.44-30.506666A298.666667 298.666667 0 0 1 128 554.666667a301.653333 301.653333 0 0 1 11.52-81.28A384 384 0 0 1 190.72 170.666667a385.706667 385.706667 0 0 1 201.386667 87.466666A306.773333 306.773333 0 0 1 426.666667 256h170.666666a306.773333 306.773333 0 0 1 34.56 2.133333A385.706667 385.706667 0 0 1 833.28 170.666667a384 384 0 0 1 51.2 302.72A301.653333 301.653333 0 0 1 896 554.666667z m-667.093333-79.573334a210.773333 210.773333 0 0 0-15.573334 71.466667A213.333333 213.333333 0 0 1 405.333333 426.666667h213.333334a213.333333 213.333333 0 0 1 192 119.893333 210.773333 210.773333 0 0 0-15.146667-71.466667 295.466667 295.466667 0 0 0-6.186667-207.786666 298.666667 298.666667 0 0 0-129.706666 83.2A207.573333 207.573333 0 0 0 597.333333 341.333333h-170.666666a207.573333 207.573333 0 0 0-61.226667 9.173334 298.666667 298.666667 0 0 0-129.706667-83.2 295.466667 295.466667 0 0 0-6.826666 207.786666zM597.333333 768a213.333333 213.333333 0 0 0 90.24-21.333333A128 128 0 0 0 618.666667 512H405.333333a128 128 0 0 0-68.906666 234.666667A213.333333 213.333333 0 0 0 426.666667 768z"
          p-id="3023"
          fill="#1296db"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import navDrawer from "@/components/UserProfile.vue";
import { mapState } from "vuex";
import NavBar from "@/components/NavBar.vue";
export default {
  name: "OverAllLayout",
  components: {
    NavBar,
    navDrawer,
  },
  data() {
    return {
      showDrawer: false,
      nftBtnShow: false,
      tab: 0,
      items: [this.$t("navDrawer.invitation")],
      // items: [this.$t("navDrawer.invitation"), this.$t("navDrawer.placement")],
    };
  },
  watch: {
    nftUrl: {
      handler(val) {
        console.log("nftUrl ", val);
        if (val !== "default" && val.length <= 0) {
          console.log(this.nftBtnShow);
          this.nftBtnShow = true;
        }
      },
      immediate: true,
    },

    showDrawer: {
      handler(newVal, oldVal) {
        this.$store.commit("system/isShowNavDrawer", newVal);
      },
    },
    isShowNavDrawer: {
      handler(newVal, oldVal) {
        this.showDrawer = newVal;
      },
    },
  },
  computed: {
    ...mapState({
      isShowNavDrawer: (state) => state.system.isShowNavDrawer,
      stakeUrl: (state) => state.user.stakeUrl,
      nftUrl: (state) => state.user.nftUrl,
      userAvatar: (state) => state.user.userAvatar,
      getAvatar() {
        return this.userAvatar || require("@/assets/image/head.png");
      },
      isShowAvatar: (state) => state.user.isShowAvatar,
    }),
    getDrawerWidth() {
      return this.isMobile ? "85%" : "320px";
    },
    isMobile() {
      console.log(
        "isMobile",
        this.$vuetify.breakpoint,
        this.$vuetify.breakpoint.mobile
      );
      return this.$vuetify.breakpoint.mobile;
    },
  },
  created() {
    this.showDrawer = this.isShowNavDrawer;
    this.changeTab(0);
  },
  methods: {
    closeAvatar() {
      this.$store.commit("user/setIsShowAvatar", false);
    },
    goToMintNft() {
      this.$router.push("/mint_nft");
    },
    goToPage() {
      if (this.stakeUrl) {
        window.location.href = this.stakeUrl;
      }
    },
    changeTab(tab) {
      this.tab = tab;
      this.$store.commit("user/setCurrentNavBarTab", tab);
    },
  },
};
</script>

<style scoped lang="scss">
.app-bar-box {
  height: 100%;
  overflow: hidden;
}

.app-bar-main {
  height: 100%;
  overflow-y: auto;
  position: absolute;
  right: 0;
}

.app-bar-mobile {
  width: 100%;
}

.app-bar-web {
  width: calc(100% - 320px);
}

.banner-title {
  display: flex;
  align-items: center;
  justify-content: end;
}

.logo-ton {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 0.5em;
}

.banner-pledge-box {
  width: 100%;
  height: 59px;
  line-height: 59px;
  background-color: rgba(0, 136, 204, 0.15);
  color: #0088cc;
  font-weight: bold;
  font-size: 1.2em;
  text-align: right;
  padding-right: 2em;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 167, 11, 0.15);
}

.banner-title-arrow {
  margin-left: 0.2em;
}

.btn-show-nft {
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 2;
}

.avatar-panel {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.avatar-panel img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
}
.user-avatar {
}
</style>
